import { Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";

import logo from "../assets/images/logo.svg";

const LoadingScreen = ({ brandName = "Moonlight" }) => {
  const [exit, setExit] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setExit(true);
    }, 800);

    return () => clearTimeout(timer);
  }, []);

  const loaderVariants = {
    exit: {
      y: "-100%",
      transition: { duration: 0.8, ease: "easeInOut" },
    },
  };
  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.8 } },
  };

  return (
    <AnimatePresence>
      {exit === false && (
        <motion.div
          variants={loaderVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "#162447",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
            color: "text.primary",
          }}
        >
          {logo ? (
            <img
              src={logo}
              alt="Brand Logo"
              style={{
                width: 200,
                marginBottom: 16,
                borderRadius: "50%",
                background: "#C375D6",
                zIndex: 2222,
              }}
            />
          ) : (
            <Typography variant="h4" sx={{ fontWeight: 700 }}>
              {brandName}
            </Typography>
          )}

          <motion.div
            variants={fadeInVariants}
            initial="hidden"
            animate="visible"
          >
            <Typography
              gutterBottom
              variant="h3"
              sx={{ fontWeight: 700, mt: 2 }}
            >
              {brandName}
            </Typography>
          </motion.div>

          {/* Loading Indicator */}
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{
              scale: 1,
              opacity: 1,
              transition: { duration: 0.5, delay: 0.3 },
            }}
            exit={{ scale: 0.8, opacity: 0, transition: { duration: 0.5 } }} // Add exit animation
          >
            Цифровое агентство
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default LoadingScreen;
