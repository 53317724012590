import { useParams, useNavigate } from "react-router-dom";
import { Box, Container } from "@mui/material";
import {
  MotionButtonComponent,
  MotionCardMediaComponent,
  MotionTypographyComponent,
} from "../Animation";

import Markdown from "react-markdown";

import blogImage1 from "../../assets/images/blogpost1.png";
import blogImage2 from "../../assets/images/blogpost2.png";
import blogImage3 from "../../assets/images/blogpost3.png";

const blogPosts = [
  {
    title: "Будущее UX: Тренды 2025 года",
    description:
      "Дизайн, управляемый ИИ, голосовые интерфейсы, и гипер-персонализация — что дальше?",
    image: blogImage1,
    topic: "Веб-разработка",
    content: `В мире UX/UI дизайна постоянно происходят изменения, и 2025 год не станет исключением. Давайте рассмотрим ключевые тренды, которые будут формировать будущее пользовательского опыта.

1. **AI-Driven Design (Дизайн, управляемый ИИ)**:
   - **Автоматизация**: Искусственный интеллект будет всё больше автоматизировать рутинные задачи, такие как создание макетов и подбор цветовых схем.
   - **Персонализация**: ИИ позволит создавать уникальные пользовательские интерфейсы, адаптированные под каждого пользователя.
   - **Прогнозирование**: Системы на основе ИИ смогут предсказывать поведение пользователей и предлагать наиболее релевантные решения.

2. **Voice Interfaces (Голосовые интерфейсы)**:
   - **Естественность**: Голосовые интерфейсы станут более естественными и интуитивно понятными.
   - **Интеграция**: Голосовое управление будет интегрировано во все сферы жизни, от умного дома до автомобилей.
   - **Доступность**: Голосовые интерфейсы сделают цифровые продукты доступнее для людей с ограниченными возможностями.

3. **Hyper-Personalization (Гипер-персонализация)**:
   - **Адаптация**: Интерфейсы будут адаптироваться под каждого пользователя, учитывая его предпочтения и поведение.
   - **Контекст**: Системы будут учитывать контекст использования, предлагая наиболее релевантный контент и функции.
   - **Эмоциональный дизайн**: Дизайн будет учитывать эмоциональное состояние пользователя, создавая более глубокую связь.

4. **Microinteractions (Микровзаимодействия)**:
   - **Детали**: Микровзаимодействия станут более тонкими и незаметными, но при этом будут улучшать пользовательский опыт.
   - **Анимация**: Анимация будет использоваться для создания более живых и динамичных интерфейсов.
   - **Обратная связь**: Микровзаимодействия будут давать пользователю мгновенную обратную связь, делая взаимодействие более понятным.

5. **Augmented Reality (Дополненная реальность)**:
   - **Интеграция**: AR будет всё больше интегрироваться в повседневную жизнь, от покупок до обучения.
   - **Интерактивность**: AR позволит создавать более интерактивные и вовлекающие пользовательские интерфейсы.
   - **Иммерсивность**: AR создаст более глубокое погружение в цифровой мир.

В заключение, 2025 год обещает быть захватывающим для UX/UI дизайнеров. Тренды, такие как дизайн, управляемый ИИ, голосовые интерфейсы, гипер-персонализация, микровзаимодействия и дополненная реальность, будут формировать будущее пользовательского опыта. Дизайнерам важно следить за этими изменениями и адаптировать свои навыки, чтобы оставаться востребованными в быстро меняющемся мире технологий.`,
  },
  {
    title: "Интернет-магазин: Секреты успешного запуска",
    description:
      "Как правильно запустить интернет-магазин и привлечь первых клиентов? Мы расскажем о ключевых шагах, которые помогут вам начать свой бизнес в интернете. От выбора ниши до первых продаж — всё, что нужно знать начинающему предпринимателю.",
    image: blogImage2,
    topic: "Маркетинг",
    content: `Запуск интернет-магазина — это захватывающее, но непростое предприятие. Чтобы ваш бизнес был успешным, важно учесть множество факторов. В этой статье мы рассмотрим ключевые шаги, которые помогут вам запустить свой интернет-магазин и привлечь первых клиентов.

1. **Выбор ниши**:
   - **Анализ рынка**: Изучите рынок и определите, какие товары или услуги востребованы.
   - **Конкуренция**: Оцените уровень конкуренции в выбранной нише.
   - **Уникальность**: Подумайте, чем ваш магазин будет отличаться от конкурентов.

2. **Бизнес-план**:
   - **Цели**: Определите краткосрочные и долгосрочные цели вашего бизнеса.
   - **Стратегия**: Разработайте стратегию развития, включая маркетинговые и операционные планы.
   - **Финансы**: Рассчитайте стартовый капитал и прогнозируемые доходы.

3. **Выбор платформы**:
   - **CMS**: Выберите подходящую систему управления контентом (CMS) для вашего магазина.
   - **Функционал**: Убедитесь, что платформа поддерживает все необходимые функции, такие как корзина, оплата, доставка.
   - **Дизайн**: Создайте привлекательный и удобный дизайн сайта.

4. **Наполнение каталога**:
   - **Качество**: Добавьте качественные фотографии и подробные описания товаров.
   - **Категории**: Организуйте товары по категориям для удобства навигации.
   - **Ассортимент**: Постепенно расширяйте ассортимент, учитывая спрос и предпочтения клиентов.

5. **Маркетинг**:
   - **SEO**: Оптимизируйте сайт для поисковых систем.
   - **Социальные сети**: Создайте страницы в социальных сетях и активно продвигайте свой магазин.
   - **Реклама**: Используйте контекстную рекламу и другие платные методы продвижения.

6. **Логистика**:
   - **Доставка**: Определите способы доставки и стоимость.
   - **Склад**: Организуйте хранение товаров.
   - **Упаковка**: Позаботьтесь о качественной упаковке.

7. **Обслуживание**:
   - **Клиентская поддержка**: Обеспечьте качественную поддержку клиентов.
   - **Обратная связь**: Собирайте отзывы и улучшайте сервис.

Следуя этим шагам, вы сможете успешно запустить свой интернет-магазин и привлечь первых клиентов. Удачи!`,
  },
  {
    title: "Тёмная тема: Больше, чем просто тренд?",
    description:
      "Как темный режим улучшает UX, экономит заряд батареи и улучшает эстетику.",
    image: blogImage3,
    topic: "UI/UX Дизайн",
    content: `Темный режим (Dark Mode) стал одним из самых популярных трендов в дизайне пользовательских интерфейсов. Но это не просто мимолетное увлечение. Темный режим имеет ряд преимуществ, которые делают его важным элементом современного UX/UI дизайна.

1. **Улучшение UX**:
   - **Снижение нагрузки на глаза**: Темный режим снижает напряжение глаз, особенно в условиях низкой освещенности.
   - **Улучшение читаемости**: На темном фоне текст становится более контрастным и читаемым.
   - **Фокус на контенте**: Темный фон помогает пользователю сосредоточиться на контенте, а не на интерфейсе.

2. **Экономия заряда батареи**:
   - **OLED-экраны**: На OLED-экранах темный режим позволяет экономить заряд батареи, так как черные пиксели не подсвечиваются.
   - **Снижение энергопотребления**: В целом, использование темного режима может снизить энергопотребление устройства.

3. **Улучшение эстетики**:
   - **Современный вид**: Темный режим придает интерфейсу современный и стильный вид.
   - **Эмоциональное воздействие**: Темный фон может создавать атмосферу таинственности и элегантности.
   - **Визуальная иерархия**: Темный режим помогает выделить важные элементы интерфейса.

4. **Доступность**:
   - **Светочувствительность**: Темный режим может быть полезен для людей с повышенной светочувствительностью.
   - **Ночное использование**: Темный режим делает использование устройств в ночное время более комфортным.

5. **Тренды**:
   - **Соответствие трендам**: Темный режим соответствует современным трендам в дизайне.
   - **Пользовательские предпочтения**: Многие пользователи предпочитают темный режим, и его наличие может повысить лояльность к продукту.

В заключение, темный режим — это не просто тренд, а важный элемент UX/UI дизайна, который улучшает пользовательский опыт, экономит заряд батареи и улучшает эстетику. Дизайнерам важно учитывать эти преимущества и предлагать пользователям возможность выбора между светлым и темным режимами.`,
  },
];

const BlogDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const post = blogPosts[id];

  if (!post) {
    return (
      <MotionTypographyComponent variant="h5">
        Статья не найдена
      </MotionTypographyComponent>
    );
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        py: { xs: 6, md: 10 },
        backgroundColor: "background.default",
        color: "text.primary",
      }}
    >
      <Container maxWidth="md">
        <MotionTypographyComponent
          variant="subtitle2"
          sx={{
            textTransform: "uppercase",
            color: "secondary.main",
            fontWeight: "bold",
            mb: 1,
          }}
        >
          {post.topic}
        </MotionTypographyComponent>
        <MotionTypographyComponent variant="h4" fontWeight="bold" mb={3}>
          {post.title}
        </MotionTypographyComponent>
        <MotionCardMediaComponent
          component="img"
          sx={{
            width: "100%",
            height: { xs: 200, md: 350 },
            objectFit: "contain",
            borderRadius: "16px",
            mb: 3,
          }}
          image={post.image}
          loading="lazy"
          alt={post.title}
        />
        {/* <MotionTypographyComponent
          variant="body2"
          sx={{ opacity: 0.9, mb: 4, textAlign: "justify" }}
        > */}
        <Markdown>{post.content}</Markdown>
        {/* </MotionTypographyComponent> */}
        <MotionButtonComponent
          variant="outlined"
          color="secondary"
          onClick={() => navigate("/")}
        >
          Назад к блогу
        </MotionButtonComponent>
      </Container>
    </Box>
  );
};

export default BlogDetail;
