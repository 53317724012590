import React from "react";

import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { HelmetProvider } from "react-helmet-async";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop"; // Import the new component

import BlogDetail from "./components/landing/BlogDetail";
import Landing from "./pages/Landing";
import theme from "./theme";

const App = () => {
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <div id="back-to-top-anchor" />
        <CssBaseline />
        <BrowserRouter
          future={{
            v7_relativeSplatPath: true,
            v7_startTransition: true,
          }}
        >
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/blog/:id" element={<BlogDetail />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
