import React from "react";
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid2 as Grid,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion"; // Import Framer Motion

/// Animation Variants

// Box: A smoother entrance with spring effect
const boxVariants = {
  hidden: { opacity: 0, scale: 0.85, y: -30 },
  visible: {
    opacity: 1,
    scale: 1,
    y: 0,
    transition: { type: "spring", stiffness: 80, damping: 12 },
  },
};

// Button: A bouncy entrance with hover effect
const buttonVariants = {
  hidden: { opacity: 0, x: -80 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { type: "spring", stiffness: 90, damping: 14, delay: 0.1 },
  },
  hover: {
    scale: 1.1,
    transition: { type: "spring", stiffness: 150, damping: 10 },
  },
};

// Card: Staggered appearance with slight rotation
const cardVariants = {
  hidden: { opacity: 0, y: 60, rotate: -2, scale: 0.95 },
  visible: {
    opacity: 1,
    y: 0,
    rotate: 0,
    scale: 1,
    transition: { type: "spring", stiffness: 80, damping: 14 },
  },
  hover: {
    scale: 1.03,
    rotate: 1,
    transition: { duration: 0.3, ease: "easeOut" },
  },
};

// Card Media: More dynamic pop-in with rotation
const cardMediaVariants = {
  hidden: { opacity: 0, scale: 0.6, rotate: -15 },
  visible: {
    opacity: 1,
    scale: 1,
    rotate: 0,
    transition: { type: "spring", stiffness: 100, damping: 12, duration: 0.5 },
  },
};

// Card Content: Soft fade-in with hover expansion
const cardContentVariants = {
  hidden: { opacity: 0, scale: 0.9 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: { duration: 0.4, ease: "easeInOut" },
  },
  hover: {
    scale: 1.08,
    transition: { duration: 0.3, ease: "easeOut" },
  },
};

// Typography: More dynamic entrance with bounce
const textVariants = {
  hidden: { opacity: 0, x: 50 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { type: "spring", stiffness: 90, damping: 12, duration: 0.5 },
  },
};

const MotionBadge = motion.create(Badge);
const MotionBox = motion.create(Box);
const MotionButton = motion.create(Button);
const MotionCard = motion.create(Card);
const MotionCardContent = motion.create(CardContent);
const MotionCardMedia = motion.create(CardMedia);
const MotionTypography = motion.create(Typography);
const MotionGrid = motion.create(Grid);

export const MotionBoxComponent = ({
  children,
  variants = boxVariants,
  initial = "hidden",
  whileInView = "visible",
  viewport = { once: true },
  ...props
}) => (
  <MotionBox
    variants={variants}
    initial={initial}
    whileInView={whileInView}
    viewport={viewport}
    {...props}
  >
    {children}
  </MotionBox>
);

export const MotionBadgeComponent = ({
  children,
  variants = textVariants,
  initial = "hidden",
  whileInView = "visible",
  viewport = { once: true },
  ...props
}) => (
  <MotionBadge
    variants={variants}
    initial={initial}
    whileInView={whileInView}
    viewport={viewport}
    {...props}
  >
    {children}
  </MotionBadge>
);

export const MotionButtonComponent = ({
  children,
  variants = buttonVariants,
  initial = "hidden",
  whileInView = "visible",
  viewport = { once: true },
  ...props
}) => (
  <MotionButton
    variants={variants}
    initial={initial}
    whileInView={whileInView}
    viewport={viewport}
    {...props}
  >
    {children}
  </MotionButton>
);

export const MotionCardComponent = ({
  children,
  variants = cardVariants,
  initial = "hidden",
  whileInView = "visible",
  viewport = { once: true },
  ...props
}) => (
  <MotionCard
    variants={variants}
    initial={initial}
    whileInView={whileInView}
    viewport={viewport}
    {...props}
  >
    {children}
  </MotionCard>
);

export const MotionCardContentComponent = ({
  children,
  variants = cardContentVariants,
  initial = "hidden",
  whileInView = "visible",
  viewport = { once: true },
  ...props
}) => (
  <MotionCardContent
    variants={variants}
    initial={initial}
    whileInView={whileInView}
    viewport={viewport}
    {...props}
  >
    {children}
  </MotionCardContent>
);

export const MotionCardMediaComponent = ({
  children,
  variants = cardMediaVariants,
  initial = "hidden",
  whileInView = "visible",
  viewport = { once: true },
  ...props
}) => (
  <MotionCardMedia
    variants={variants}
    initial={initial}
    whileInView={whileInView}
    viewport={viewport}
    {...props}
  >
    {children}
  </MotionCardMedia>
);

export const MotionTypographyComponent = ({
  children,
  variants = textVariants,
  initial = "hidden",
  whileInView = "visible",
  viewport = { once: true },
  ...props
}) => (
  <MotionTypography
    variants={variants}
    initial={initial}
    whileInView={whileInView}
    viewport={viewport}
    {...props}
  >
    {children}
  </MotionTypography>
);

export const MotionGridComponent = ({
  children,
  variants = boxVariants,
  initial = "hidden",
  whileInView = "visible",
  viewport = { once: true },
  ...props
}) => (
  <MotionGrid
    variants={variants}
    initial={initial}
    whileInView={whileInView}
    viewport={viewport}
    {...props}
  >
    {children}
  </MotionGrid>
);
