import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import logo from "../assets/images/logo.png";

const pages = [
  { name: "О нас", path: "about" },
  { name: "Преимущества", path: "benefits" },
  { name: "Процесс", path: "process" },
  { name: "Отзывы", path: "testimonials" },
  { name: "Блог", path: "blog" },
  { name: "FAQ", path: "faq" },
];

const Navbar = ({ open, setOpen }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [visible, setVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    let ticking = false; // Prevent multiple calls in the same frame

    const handleScroll = () => {
      if (!ticking) {
        requestAnimationFrame(() => {
          const currentScrollY = window.scrollY;
          setVisible(lastScrollY > currentScrollY || currentScrollY < 10);
          setLastScrollY(currentScrollY);
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  return (
    <AppBar
      position="fixed"
      sx={{
        background: "secondary.main",
        backgroundImage: "none",
        backgroundColor: "secondary.main  ",
        // boxShadow: "none",
        px: 1,
        // py: 1,
        transform: visible ? "translateY(0)" : "translateY(-100%)",
        transition: "transform 0.3s ease-in-out",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar
        sx={{
          maxWidth: "lg",
          mx: "auto",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "transparent",
          backgroundColor: "transparent",
        }}
      >
        <Link
          href={`#hero`}
          to="hero"
          spy={true}
          smooth={true}
          duration={500}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{
              width: "56px",
              height: "56px",
              objectFit: "contain",
              borderRadius: "50%",
              cursor: "pointer",
              transition: "transform 0.3s ease",
              background: "#C375D6",
            }}
            onMouseEnter={(e) => (e.target.style.transform = "scale(1.1)")}
            onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
          />
        </Link>

        {/* Desktop Navigation */}
        <Box sx={{ display: { xs: "none", md: "flex" }, gap: 3 }}>
          {pages.map((page) => (
            <motion.div
              key={page.name}
              whileHover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <Typography
                variant="body2"
                component={Link}
                href={`#${page.path}`}
                to={page.path}
                smooth={true}
                spy={true}
                duration={500}
                sx={{
                  color: "text.primary",
                  cursor: "pointer",
                  fontWeight: "bold",
                  position: "relative",
                  textTransform: "uppercase",
                  textDecoration: "none",
                  "&:after": {
                    content: '""',
                    position: "absolute",
                    width: "100%",
                    height: "2px",
                    bottom: "-3px",
                    left: "0",
                    backgroundColor: "text.primary",
                    transform: "scaleX(0)",
                    transformOrigin: "right",
                    transition: "transform 0.3s ease",
                  },
                  "&:hover:after": {
                    transform: "scaleX(1)",
                    transformOrigin: "left",
                  },
                }}
              >
                {page.name}
              </Typography>
            </motion.div>
          ))}
        </Box>

        {/* Get Started Button */}
        <Button
          variant="contained"
          color="accent"
          onClick={() => setOpen(true)}
          sx={{
            display: { xs: "none", md: "flex" },
            ml: 3,
            borderRadius: "24px",
          }}
        >
          Оставить заявку
        </Button>

        {/* Mobile Menu Button with Icon Transition */}
        <IconButton
          onClick={() => setDrawerOpen((prev) => !prev)}
          aria-label={drawerOpen ? "Закрыть меню" : "Открыть меню"}
          sx={{ display: { xs: "flex", md: "none" }, color: "text.primary" }}
        >
          <AnimatePresence mode="wait" initial={false}>
            {drawerOpen ? (
              <motion.div
                key="close"
                initial={{ rotate: -90 }}
                animate={{ rotate: 0 }}
                exit={{ rotate: 90 }}
              >
                <CloseIcon
                  type="button"
                  sx={{
                    display: { xs: "flex", md: "none" },
                    color: "text.primary",
                    border: "1px solid #fff",
                    borderRadius: "50%",
                    p: 1,
                    fontSize: "2.5rem",
                  }}
                />
              </motion.div>
            ) : (
              <motion.div
                key="menu"
                initial={{ rotate: 90 }}
                animate={{ rotate: 0 }}
                exit={{ rotate: -90 }}
              >
                <MenuIcon
                  type="button"
                  sx={{
                    display: { xs: "flex", md: "none" },
                    color: "text.primary",
                    border: "1px solid #fff",
                    borderRadius: "50%",
                    p: 1,
                    fontSize: "2.5rem",
                  }}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </IconButton>
      </Toolbar>

      <Drawer
        anchor="top"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: {
            background: "transparent",
            backgroundColor: "rgba(18, 52, 153, 0.3)",
            // backgroundColor: "accent.main",
            backdropFilter: "blur(10px)",
            color: "text.primary",
            minHeight: "100dvh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "center",
            pt: "80px",
            pb: 4,
          },
        }}
      >
        <List sx={{ py: 2 }}>
          {pages.map((page, index) => (
            <motion.div
              key={page.name}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <ListItem>
                <ListItemButton
                  component={Link}
                  href={`#${page.path}`}
                  to={page.path}
                  smooth={true}
                  duration={500}
                  onClick={() => setDrawerOpen(false)}
                  sx={{
                    textAlign: "center",
                    color: "text.primary",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    "&:hover": { color: "secondary.main" },
                    textDecoration: "none",
                  }}
                >
                  <ListItemText primary={page.name} disableTypography />
                </ListItemButton>
              </ListItem>
            </motion.div>
          ))}
        </List>
        <Box
          sx={{
            py: 4,
          }}
        >
          {/* Contact Form Open Button */}
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setOpen(true)}
            size="large"
            sx={{
              height: "100%",
              borderRadius: "24px",
              "&:hover": {
                "&::before": {
                  content: '""',
                  top: "-100%",
                  left: "-100%",
                  width: "250%",
                  height: "250%",
                  background:
                    "linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.4), transparent)",
                  transition: "all 0.8s ease",
                },
              },
            }}
          >
            Оставить заявку
          </Button>
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default Navbar;
