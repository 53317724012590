import { Box } from "@mui/material";
import { lazy, Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import LoadingScreen from "../components/LoadingScreen";
import Navbar from "../components/Navbar";
import Contact from "../components/landing/Contact";
const Hero = lazy(() => import("../components/landing/Hero"));
const About = lazy(() => import("../components/landing/About"));
const Benefits = lazy(() => import("../components/landing/Benefits"));
const Process = lazy(() => import("../components/landing/Process"));
const SocialProof = lazy(() => import("../components/landing/SocialProof"));
const Testimonials = lazy(() => import("../components/landing/Testimonials"));
const Blog = lazy(() => import("../components/landing/Blog"));
const FAQ = lazy(() => import("../components/landing/FAQ"));
const CallToAction = lazy(() => import("../components/landing/CallToAction"));
const Footer = lazy(() => import("../components/Footer"));
const ContactForm = lazy(() => import("../components/ContactForm"));
const TelegramButton = lazy(() => import("../components/TelegramButton"));

export default function Landing() {
  const [contactFormOpen, setContactFormOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  // Wait for all lazy components to load before triggering animations
  useEffect(() => {
    const timer = setTimeout(() => setIsLoaded(true), 2000); // Simulating async load
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Helmet>
        <title>Moonlight – Цифровое агентство</title>
        <meta
          name="description"
          content="Мы создаем лендинги, интернет-магазины и digital-решения для роста вашего бизнеса."
        />
        <meta
          name="keywords"
          content="веб-разработка, дизайн, маркетинг, лендинги, интернет-магазины, SEO"
        />
        <meta name="author" content="Moonlight Цифровое Агентство" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://moonlight.uz/" />

        <meta property="og:title" content="Moonlight – Цифровое агентство" />
        <meta
          property="og:description"
          content="Создаем цифровые продукты, которые выделяют ваш бренд среди конкурентов."
        />
        <meta property="og:image" content="https://moonlight.uz/logo512.png" />
        <meta property="og:url" content="https://moonlight.uz/" />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Moonlight – Цифровое агентство" />
        <meta
          name="twitter:description"
          content="Создаем цифровые продукты для роста вашего бизнеса."
        />
        <meta name="twitter:image" content="https://moonlight.uz/logo512.png" />

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1"
        />
      </Helmet>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            name: "Moonlight Agency", // e.g., "Design Agency X"
            image: "https://moonlight.uz/logo512.png",
            description:
              "Мы предлагаем услуги по созданию лендингов, интернет-магазинов и digital-решений для роста вашего бизнеса.",
            url: "https://moonlight.uz",
            telephone: "+998 90 023 27 96",
            address: {
              "@type": "PostalAddress",
              streetAddress: "Массив Сувсоз-2, дом 37",
              addressLocality: "Ташкент",
              addressRegion: "Tashkent",
              postalCode: "100213",
              addressCountry: "UZ",
            },
            openingHours: ["Mo-Fr 09:00-18:00"],
            priceRange: "UZS",
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "+998 90 023 27 96",
              contactType: "customer service",
              availableLanguage: ["Russian", "Uzbek"],
            },
          }),
        }}
      />
      <main>
        {!isLoaded ? <LoadingScreen /> : null}
        <Suspense fallback={<LoadingScreen />}>
          <Navbar open={contactFormOpen} setOpen={setContactFormOpen} />

          <Box id="scrollContainer" sx={{ position: "relative", zIndex: 2 }}>
            <Box id="hero" sx={{ overflow: "hidden" }}>
              <Hero open={contactFormOpen} setOpen={setContactFormOpen} />
            </Box>
            <Box id="about">
              <About open={contactFormOpen} setOpen={setContactFormOpen} />
            </Box>
            <Box id="benefits" sx={{ overflow: "hidden" }}>
              <Benefits open={contactFormOpen} setOpen={setContactFormOpen} />
            </Box>
            <Box id="process" sx={{ overflow: "hidden" }}>
              <Process open={contactFormOpen} setOpen={setContactFormOpen} />
            </Box>
            <Box id="socialProof" sx={{ overflow: "hidden" }}>
              <SocialProof />
            </Box>
            <Box id="testimonials" sx={{ overflow: "hidden" }}>
              <Testimonials />
            </Box>
            <Box id="faq" sx={{ overflow: "hidden" }}>
              <FAQ />
            </Box>
            <Box id="contact" sx={{ overflow: "hidden" }}>
              <Contact />
            </Box>

            <Box id="blog" sx={{ overflow: "hidden" }}>
              <Blog />
            </Box>

            <Box id="callToAction" sx={{ overflow: "hidden" }}>
              <CallToAction
                open={contactFormOpen}
                setOpen={setContactFormOpen}
              />
            </Box>
            <Box id="footer" sx={{ overflow: "hidden" }}>
              <Footer />
            </Box>
            <ContactForm
              open={contactFormOpen}
              handleClose={() => setContactFormOpen(false)}
            />
          </Box>
          <TelegramButton />
        </Suspense>
      </main>
    </>
  );
}
