import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#0B132B", // Midnight blue (more mysterious)
      paper: "#162447", // Deep twilight blue
    },
    primary: {
      main: "#1B2A58", // Darker moonlight blue
      light: "#27467E", // Soft bluish glow
      dark: "#101B3F", // True deep night blue
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#9E6DC2", // Moonlit lavender
      light: "#B48CD9",
      dark: "#7A489C",
      contrastText: "#FFFFFF",
    },
    accent: {
      main: "#C7D3F4", // Soft moonlight glow
      light: "#E2E8FA",
      dark: "#A6B1D6",
      contrastText: "#101B3F",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#D1DBF0", // Softer moonlight reflection
    },
    action: {
      hover: "rgba(10, 36, 114, 0.1)", // Subtle blue hover effect
      selected: "rgba(10, 36, 114, 0.2)", // Slightly stronger selection color
    },
  },
  typography: {
    fontFamily: `"Gabriela", "Merriweather", sans-serif`,
    root: {
      fontFamily: `"Gabriela", "Merriweather", sans-serif`,
      fontSize: "1rem",
      color: "#FFFFFF",
    },
    h1: {
      fontFamily: `"Prosto One", "Space Grotesk", sans-serif`,
      fontWeight: 700,
      fontSize: "clamp(2.8rem, 5vw, 3.8rem)",
      letterSpacing: "-0.02em",
    },
    h2: {
      fontFamily: `"Prosto One", sans-serif`,
      fontWeight: 600,
      fontSize: "clamp(2.2rem, 4vw, 3rem)",
      letterSpacing: "-0.015em",
    },
    h3: {
      fontFamily: `"Prosto One", sans-serif`,
      fontWeight: 600,
      fontSize: "clamp(2rem, 3vw, 2.5rem)",
      letterSpacing: "-0.01em",
    },
    h4: {
      fontFamily: `"Prosto One", sans-serif`,
      fontWeight: 600,
      fontSize: "clamp(1.6rem, 2.5vw, 2rem)",
      letterSpacing: "-0.01em",
    },
    h5: {
      fontFamily: `"Prosto One", sans-serif`,
      fontWeight: 600,
      fontSize: "clamp(1.3rem, 2vw, 1.5rem)",
      letterSpacing: "-0.01em",
    },
    h6: {
      fontFamily: `"Prosto One" sans-serif`,
      fontWeight: 600,
      fontSize: "clamp(1.1rem, 1.5vw, 1.2rem)",
      letterSpacing: "-0.01em",
    },

    body1: {
      fontFamily: `"Gabriela", "Merriweather", sans-serif`,
      fontSize: "1.2rem",
      letterSpacing: "0.01em",
      lineHeight: 1.5,
    },
    body2: {
      fontFamily: `"Gabriela", "Merriweather", sans-serif`,
      fontSize: "1rem",
      letterSpacing: "0.01em",
      lineHeight: 1.5,
    },
    button: {
      fontFamily: `"Gabriela", "Merriweather", sans-serif`,
      fontWeight: 600,
      textTransform: "none",
      letterSpacing: "0.05em",
      fontSize: "1rem",
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          transition: "color 0.3s ease-in-out",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          position: "relative",
          overflow: "hidden",
          borderRadius: "50px",
          fontWeight: "bold",
          textTransform: "none",
          background: "#123499", // Основной фиолетовый фон
          color: "#FFFFFF",
          border: "1px solid #123499", // Граница того же цвета
          transition: "all 0.3s ease-in-out",
          boxShadow: "0 4px 10px #123499", // Более выраженная тень
          "&:hover": {
            "&::before": {
              content: '""',
              position: "absolute",
              top: "-100%",
              left: "-100%",
              width: "250%",
              height: "250%",
              background:
                "linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.4), transparent)",
              transition: "all 0.8s ease",
            },
          },
        },
      },
    },
  },
});

export default theme;
