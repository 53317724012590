import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Container,
  Grid2 as Grid,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import contactIllustration from "../../assets/images/contact.png"; // 👈 your illustration here
import { MotionTypographyComponent } from "../Animation";

const schema = yup.object().shape({
  name: yup.string().min(3, "Минимум 3 буквы").required("Введите имя"),
  phone: yup
    .string()
    .matches(/\+998 \d{2} \d{3} \d{2} \d{2}/, "Формат: +998 XX XXX XX XX")
    .required("Введите телефон"),
  message: yup
    .string()
    .min(5, "Минимум 5 символов")
    .required("Введите сообщение"),
});

const ContactSection = () => {
  const token = process.env.REACT_APP_TOKEN;
  const channelId = process.env.REACT_APP_CHANNEL_ID;

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { name: "", phone: "+998 ", message: "" },
  });

  const formatPhone = (event) => {
    let input = event.target.value.replace(/\D/g, "");
    if (!input.startsWith("998")) {
      input = "998" + input;
    }
    input = input.substring(3);
    let formatted = `+998 ${input.slice(0, 2)} ${input.slice(
      2,
      5
    )} ${input.slice(5, 7)} ${input.slice(7, 9)}`.trim();
    if (formatted.length < 5) {
      formatted = "+998 ";
    }
    setValue("phone", formatted, { shouldValidate: true });
    trigger("phone");
  };

  const onSubmit = (data) => {
    fetch("https://api.telegram.org/bot" + token + "/sendMessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chat_id: channelId,
        text: `Новая заявка!\nИмя: ${data.name}\nТелефон: ${data.phone}\nСообщение: ${data.message}`,
      }),
    });

    alert("Заявка успешно отправлена!");
    setValue("name", "");
    setValue("phone", "+998 ");
    setValue("message", "");
  };

  return (
    <Box
      component="section"
      sx={{
        backgroundColor: "background.default",
        color: "text.primary",
        py: { xs: 6, md: 10 },
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={6}
          justifyContent="space-between"
          alignItems={"center"}
        >
          {/* Left Illustration */}
          <Grid
            size={{ xs: 12, md: 6 }}
            sx={{
              pointerEvents: "none",
              userSelect: "none",
            }}
            draggable="false"
            onContextMenu={(e) => e.preventDefault()}
          >
            <Box
              component="img"
              src={contactIllustration}
              alt="Contact Illustration"
              sx={{
                width: "100%",
                height: "auto",
                maxHeight: 400,
                objectFit: "contain",
              }}
            />
          </Grid>

          {/* Right Form */}
          <Grid size={{ xs: 12, md: 6 }}>
            <MotionTypographyComponent
              variant="body2"
              color="secondary.main"
              sx={{
                textTransform: "uppercase",
                letterSpacing: "0.1em",
                fontWeight: 600,
                mb: 2,
              }}
            >
              Связаться с нами
            </MotionTypographyComponent>

            <MotionTypographyComponent
              variant="h3"
              fontWeight="bold"
              sx={{ mb: 4 }}
            >
              Остались вопросы? Напишите нам!
            </MotionTypographyComponent>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid size={12}>
                  <TextField
                    color="secondary"
                    fullWidth
                    label="Ваше имя"
                    {...register("name")}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                </Grid>
                <Grid size={12}>
                  <TextField
                    color="secondary"
                    fullWidth
                    label="Телефон"
                    {...register("phone")}
                    onChange={formatPhone}
                    inputProps={{ inputMode: "numeric" }}
                    error={!!errors.phone}
                    helperText={errors.phone?.message}
                  />
                </Grid>
                <Grid size={12}>
                  <TextField
                    color="secondary"
                    fullWidth
                    multiline
                    minRows={4}
                    label="Сообщение"
                    {...register("message")}
                    error={!!errors.message}
                    helperText={errors.message?.message}
                  />
                </Grid>
                <Grid size={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    fullWidth
                  >
                    Отправить
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ContactSection;
